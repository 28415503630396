import { round } from '.'

/**
 * 將API回應的體重原始資料，依照spec將量測數值四捨五入
 * @param {any} data API的原始體重數據
 * @returns 前處理過後的體重資訊
 */
export function preprocessBodyWeightFat(data) {
  const rtnData = JSON.parse(JSON.stringify(data))
  const supportFields = getMeasurementDisplayFields(
    'bodyWeightFat',
    data.deviceID
  )
  supportFields.forEach((item) => {
    rtnData[item.field] = Number(rtnData[item.field] ?? 0)
    if (item.field === 'bodyFatMass')
      rtnData[item.field] = round((data.bodyFat / 100) * data.weight, 1)
    else rtnData[item.field] = round(data[item.field], item.precision)
  })
  return rtnData
}

/**
 * [量測設備顯示資料對照表](https://docs.google.com/document/d/1ebf3h4_3gYTgsi1OXhU5EGFkQ-Z62GNkmOOMv93NrSA/edit#heading=h.4zehy6orii65)
 * @param {string} measureType 量測種類(support value: `bodyWeightFat`)
 * @param {string} deviceID 量測設備ID
 * @returns {{
 * field: string,
 * displayName: string,
 * unit: string,
 * precision: number
 * supportDevices: string[]
 * }[]}
 */
export function getMeasurementDisplayFields(measureType, deviceID = undefined) {
  const rtn = []
  if (measureType === 'bodyWeightFat') {
    const RM_BF_LS212_B = 'RM_BF_LS212-B'
    const RM_BF_WG260 = 'RM_BF_WG260'
    const allDeviceList = [RM_BF_LS212_B, RM_BF_WG260]
    rtn.push({
      field: 'weight',
      displayName: '體重',
      unit: '公斤',
      precision: 2,
      supportDevices: allDeviceList,
    })
    rtn.push({
      field: 'musclePercentage',
      displayName: '肌肉率',
      unit: '%',
      precision: 1,
      supportDevices: [RM_BF_LS212_B],
    })
    rtn.push({
      field: 'muscleMass',
      displayName: '肌肉量',
      unit: '公斤',
      precision: 1,
      supportDevices: [RM_BF_WG260],
    })
    // 這個欄位要自己計算，API沒提供這個欄位
    rtn.push({
      field: 'bodyFatMass',
      displayName: '體脂肪量',
      unit: '公斤',
      precision: 1,
      supportDevices: [RM_BF_WG260],
    })
    rtn.push({
      field: 'bmi',
      displayName: 'BMI',
      unit: undefined,
      precision: 1,
      supportDevices: allDeviceList,
    })
    rtn.push({
      field: 'bodyFat',
      displayName: '體脂率',
      unit: '%',
      precision: 1,
      supportDevices: allDeviceList,
    })
    rtn.push({
      field: 'visceralFat',
      displayName: '內臟脂肪指數',
      unit: '等級',
      precision: 0,
      supportDevices: allDeviceList,
    })
    rtn.push({
      field: 'bmr',
      displayName: '基礎代謝率',
      unit: '大卡',
      precision: 0,
      supportDevices: allDeviceList,
    })
    rtn.push({
      field: 'skeletalMuscleMassPercentage',
      displayName: '骨骼肌率',
      unit: '%',
      precision: 1,
      supportDevices: [RM_BF_WG260],
    })
    rtn.push({
      field: 'bone',
      displayName: '骨量',
      unit: '公斤',
      precision: 1,
      supportDevices: [RM_BF_WG260],
    })
    rtn.push({
      field: 'bodyWater',
      displayName: '體水份率',
      unit: '%',
      precision: 1,
      supportDevices: [RM_BF_WG260],
    })
    rtn.push({
      field: 'physicalAge',
      displayName: '身體年齡',
      unit: '歲',
      precision: 0,
      supportDevices: allDeviceList,
    })
  }

  if (deviceID) return rtn.filter((e) => e.supportDevices.includes(deviceID))

  return rtn
}
